/* Auth.css */
.auth-container {
  width: 50%;
  /* max-width: 400px; */
  margin: 50px auto;
  padding: 20px;
  border: 0.5px solid #2c2b2b;
  border-radius: 8px;
  box-shadow: 0 0 30px rgb(0, 0, 0);
}

form {

  display: flex;
  flex-direction: column;
}

label {
  margin-bottom: 5px;
}

input {
  margin-bottom: 15px;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

button {
  padding: 10px;
  background: #000000ad;
  /* background: linear-gradient(90deg, #343a40 0%, #3d4043 37%, #2f2f30 100%); */
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 10px;

}
button {
  background-color: gray;
}
button:hover {
  background-color:red;
}