.raiseTicketContainer{
    width: 100%;
    background-color: #6c757d;
    color: white;
    display: flex;
    /* height: 100vh; */
    
    align-items: center;
    flex-direction: column;
}
.raise{
    width: 99%;
    border-radius: 7px;
    margin-top: 10px;
    

}
.raise1{
    background-color: #171a1cc9;
    height: 40px;
    display: flex;
    /* justify-content: center; */
    align-items: center;
    justify-content: space-between;
    padding:  25px;
    

}
.raise1 div span {
    margin-left: 25px;
    padding-left: 20px;
    border-left: 1px solid white;
    cursor: pointer;
}
.addTicketContainer{ 
    width:50%;
    display: flex;
    justify-content: flex-end;
    align-items: center;

}
.addTicketContainer button{
    width:auto;
    background-color: red;
    border-radius: 7px;
    height: 45px;
    
    


}
.addTicketContainer button span{
   border-right: 1px solid black;
   padding-right: 10px;
   margin-right: 10px;

}
.raise2{
    background-color: #171a1cc9;
    /* height: 70px; */
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 3px 2% ;
}