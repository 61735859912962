.search_container {
  margin-top: 16px;
  display: flex;
  gap: 20px;
  justify-content: space-between;
  align-items: center;
  width: 100%;
 
}
.search_container input {
  height: 43px;
  width: 80%;
  font-size: 14px;
  background-image: url(https://cdn2.hubspot.net/hubfs/4004166/bioticresearch_website_assets/images/search_icon.png);
  background-repeat: no-repeat;
  background-position: left center;
  padding-left: 40px;
  background-color: white;
  outline: none;
  box-shadow: none;
  border: none;
  border-radius: 10px 0 0 10px;
}

.search_container button {
  height: 45px;
  margin-left: -1;
  border: none;
  border-radius: 0 10px 10px 0;
  width: 100px;
  color: white;
}

.dashboard {
  background-color: #171a1cc9;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  border-radius: 10px;
  color: white;
  overflow: hidden;
}

.dashboard .resource {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}

.dashboard .dropdowns {
  display: flex;
  justify-content: space-between;
  height: max-content;
}

.dashboard .dropdowns .dropdown {
  display: flex;
  align-items: center;
  gap: 10px;
}

.dashboard .dropdowns .dropdown select {
  width: max-content;
  padding: 5px;
  border-radius: 10px;
  background-color: #f0f8ff;
}
