.during-popup{
    background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7));
}

.PopUp{
    position: fixed;
    text-align: center;

    left: 25%;
    top: 30%;

    width: 50%;

    /* border: 1px solid #011627; */
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.5) 0px 50px 100px -20px, rgba(2, 2, 2, 0.7) 0px 30px 60px -30px, rgba(10, 37, 64, 0.5) 0px -2px 6px 0px inset;
    background-color: #FDFFFC;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    color: #011627;
}
.pu-button-container{
  width: 90%;
}

.pu-button-container button{
  margin-bottom: 10px;
  margin-top: 0px;
  padding: 7px;
  width: 100%;
  background-color: green;
  border: none;
  border-radius: 7px;
  font-weight: bolder;
  
}