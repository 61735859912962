.container1 {
  background-color:#6c757d;
  width: 80%;
  flex-grow: 1;
  padding: 5px;
  height: 100vh;
}

.firstContainer {
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  justify-content: space-between;
  margin: 5px;
  /* border: 1px solid red; */
  height: 50px;
}

.firstContainer > p {
  transition: all 0.3s ease;
}

.firstContainer :hover {
  background-color: #808080;
}
.row1 {
  width: 49%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  cursor: pointer;
  /* border: 1px solid black; */
  border-radius: 7px;
  background-color: #212529;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  color: #fffffff5;
}
.row1 span {
  font-size: large;
  font-weight: bold;
}

.row2 {
  justify-content: space-between;
  width: 33.3%;
  margin: auto;
  cursor: pointer;
  margin: 5px;
  border-radius: 7px;
  padding: 7px 0px;
  /* border: 1px solid red; */
  background-color: #343a40;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  color: #fffffff5;
}

.inputContainer {
  width: 98.5%;
  margin: auto;
  display: flex;
  justify-content: space-around;
  /* margin: 5px 5px 15px 5px; */
  /* border: 1px solid red; */
  background-color: #343a40;
  border-radius: 7px;
  padding: 7px 0px;
  margin-bottom: 10px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  color: #fffffff5;;
}

.inputBox {
  height: 30px;
  border: 0.5px solid rgb(66, 66, 66);
  border-radius: 5px;
}

.inputLabelContainer > {
  /* background-color: white; */
  padding: 5px;
  font-size: 16px;
}

.inputLabelContainer > input,
.inputLabelContainer > select {
  margin-left: 20px;
}

.uploadFileContainer {
  height: 50vh;
  /* background-color: aqua; */
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px;
  border-radius: 7px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

select {
  height: 40px;
  border: none;
  border-radius: 3px;
  /* box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; */
}

/* input{
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
} */

.submitUpload {
  margin: 10px;
  margin-top: 20px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.submitUpload:hover {
  background: linear-gradient(45deg, rgb(0, 4, 98), rgb(12, 150, 26));
  color: white;
}

.uploadInputs {
  width: 48%;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.inputUploadContainer {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-top: 10px;
}

.uploadFileContainer label input {
  border: none;
}

.card_container::-webkit-scrollbar {
  width: 8px;               /* width of the entire scrollbar */
}