.media:hover{
    transition: all 0.3s ease;
    transform: scale(1.09);
    z-index: 1;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1)
}
.mcard:hover{
    transition: all 0.3s ease;
    transform: scale(1.09);
    z-index: 1;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1)
}

.media:hover .media_child{
    opacity: 1;
}